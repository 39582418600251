$boxShadow: rgb(60 64 67 / 30%) 0 1px 2px 0, rgb(60 64 67 / 15%) 0 1px 3px 1px;
$goHomeLinkRight: 3rem; // 48px
$headerWidth: 24rem; // 384px
$searchWidth: 18.75rem; // 300px
$searchWidthSmall: 12rem; // 192px
$titleGap: 0.75rem; // 12px
$titleMarginVertical: 2.5rem; // 40px

:root {
  --HeaderBackgroundColor: var(--color1);
  --HeaderTitleColor: var(--color3);
}

.Container {
  z-index: var(--zIndexHeader);

  display: flex;
  gap: var(--offsetXL);
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--heightHeader);
  padding: var(--offsetZero) var(--offsetXL);

  background-color: var(--HeaderBackgroundColor);
  box-shadow: $boxShadow;
}

.Logo {
  display: flex;
  gap: $titleGap;
  align-items: center;

  color: var(--HeaderTitleColor);
  font-size: var(--fontM);
  text-align: center;

  --SVGIconStroke: var(--color3);
}

.Search {
  width: $searchWidth;

  transition: width var(--transitionSlow) ease-in-out;
}

.Widgets {
  display: flex;
  gap: var(--offsetXL);
  align-items: center;

  .Buttons {
    display: flex;
    gap: $titleGap;
    align-items: center;
  }

  .GoHomeLink,
  .ThemeSwitch {
    padding: var(--offsetXS);

    --SVGIconStroke: var(--color3);
    --SVGIconStrokeHover: var(--color6);
  }
}

@media all and (max-width: 788px) {
  .Container {
    gap: var(--offsetL);
  }

  .Title {
    display: none;
  }

  .Search {
    flex: 1;
    width: auto;
  }
}
