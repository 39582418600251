.Navigation {
  padding: var(--offsetM) var(--offsetZero);
}

.ItemList {
  margin: var(--offsetZero);
  padding: var(--offsetM) var(--offsetZero) var(--offsetM) var(--offsetM);

  list-style: none;

  .Item {
    position: relative;

    display: flex;
    flex: 1;
    gap: var(--offsetM);
    align-items: center;
    justify-content: flex-start;
    padding-bottom: var(--offsetS);

    font-size: var(--fontS);

    transition: all var(--transitionNormal) ease-in-out;

    user-select: none;

    &:not(:last-child) {
      margin-bottom: var(--offsetL);
    }

    &::after {
      position: absolute;
      bottom: var(--offsetZero);
      left: var(--offsetZero);

      width: var(--offsetZero);
      height: var(--offsetXS);

      background-color: var(--color13);

      transition: all var(--transitionNormal) ease-in-out;

      content: "";
    }

    &.ItemActive {
      &::after {
        width: 100%;
      }
    }
  }

  .ItemLink {
    color: var(--color9);
    text-decoration: none;

    transition: all var(--transitionNormal) ease-in-out;

    &:hover {
      color: var(--color13);
    }
  }

  .ItemLinkActive {
    color: var(--color13);
    text-decoration: none;
  }
}

.Accordion {
  width: 100%;
}

.AccordionHeader {
  --SubtitleColor: var(--color10);
  --SVGIconStroke: var(--color10);
  --SVGIconStrokeHover: var(--color3);
}
