.Container {
  padding-bottom: var(--offset-zero);

  .ArticleHeader {
    --ArticleHeaderBorderColor: var(--color7);
  }

  .CopyHref {
    position: absolute;

    transform: translateX(calc(-100% - (var(--offsetXL) - 100%) / 2));
  }

  .Props {
    flex-direction: column;
    align-items: flex-start;
  }

  .PropsList {
    display: flex;
    flex-wrap: wrap;
    margin: var(--offsetZero);
    padding: var(--offsetZero);
  }

  .PropContainer {
    width: 100%;
    overflow: hidden;

    list-style: none;

    .Name {
      margin-right: var(--offsetS);

      --CaptionColor: var(--color10);

      font-weight: 700;
    }

    .Type {
      font-weight: 700;
      font-style: italic;
    }

    .Description {
      margin-top: var(--offsetZero);
    }
  }

  :global {
    .ExternalLink {
      display: inline-block;

      color: var(--color10);
      font-weight: 500;
      font-style: italic;

      text-decoration: none;
    }
  }
}

:global(.Dark) {
  .Container {
    .ArticleHeader {
      --ArticleHeaderBorderColor: var(--color7);
    }
  }
}

.Accordion {
  width: 100%;
}
