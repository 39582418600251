:root {
  --ReactCustomHooksBackgroundColor: var(--color6);
}

:global(.Dark) {
  --ReactCustomHooksBackgroundColor: var(--color1);
}

.Container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.ContentWrap {
  position: relative;

  display: flex;
  width: 100%;
  height: calc(100% - var(--heightHeader));
}

.Content {
  flex: 1;

  background-color: var(--ReactCustomHooksBackgroundColor);
}
